import {
    GET_USER_INFO_LOGGED_PENDING,
    GET_USER_INFO_LOGGED_FULFILLED,
    GET_USER_INFO_LOGGED_REJECTED,
    UPDATE_USER_INFO_PENDING,
    UPDATE_USER_INFO_FULFILLED,
    UPDATE_USER_INFO_REJECTED
} from "../actions/types";

const initialState = {
    data: {},
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER_INFO_LOGGED_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_USER_INFO_LOGGED_FULFILLED:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        case GET_USER_INFO_LOGGED_REJECTED:
            return {
                ...state,
                data: {},
                loading: false,
                error: true,
            };
        case UPDATE_USER_INFO_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case UPDATE_USER_INFO_FULFILLED: {
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        }
        case UPDATE_USER_INFO_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
}