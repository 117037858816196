import React from "react";
import { useDispatch } from "react-redux";
import { setAuthToken } from "../actions/authActions";
import { getToken } from "../utils";
const AuthHandler = ({ children }) => {
  const dispatch = useDispatch();
  const token = getToken();
  if (token) {
    dispatch(setAuthToken(token));
  }
  return <>{children}</>;
};
export default AuthHandler;
