import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { withStyles, TextField, Box } from '@material-ui/core'
import { CheckCircleOutline, Edit, ErrorOutline } from '@material-ui/icons';
import { connect } from 'react-redux';

export const styles = (theme) => {
    return ({
        label: {
            fontFamily: 'Poppins',
            marginBottom: '5px',
            fontSize: '12px',
            letterSpacing: '0.4px',
            lineHeight: '16px',
            color: 'rgba(0, 0, 0, 0.38)'
        },
        input: {
            color: 'black',
            outline: 0,
            paddingLeft: '10px'
        },
        errorInput: {
            border: '1px solid #FF2272'
        },
        errorLabel: {
            color: '#FF2272',
        },
        fullWidth: {
            width: '100%',
            '& .MuiFormLabel-root.Mui-focused': {
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: '1.25px'
            },
            //helper text
            '& .MuiFormHelperText-root.Mui-error': {
                position: 'absolute',
                bottom: -20
            },
            //text label
            '& .MuiFormLabel-root': {
                color: theme.palette.variants && theme.palette.variants.variant4
            },
            //line disabled
            '& .MuiInput-underline': {
                '&::before': {
                    borderBottom: `1px solid ${theme.palette.variants && theme.palette.variants.variant2}`
                },
                '&:hover': {
                    borderBottom: `0px solid ${theme.palette.variants && theme.palette.variants.variant2}`
                }
            }
        },
        fullWidthDrawer: {
            width: '100%',
            '& .MuiFormLabel-root.Mui-focused': {
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: '1.25px',
            },
            //helper text
            '& .MuiFormHelperText-root.Mui-error': {
                bottom: 0,
                fontSize: 11
            },
            //text label
            '& .MuiFormLabel-root': {
                color: theme.palette.variants && theme.palette.variants.variant4
            },
            //line disabled
            '& .MuiInput-underline': {
                '&::before': {
                    borderBottom: 'none'
                },
                '&:hover': {
                    borderBottom: `0px solid ${theme.palette.variants && theme.palette.variants.variant2}`
                }
            },
            '& .MuiInputBase-input.MuiInput-input': {
                fontSize: 30,
                color: theme.palette.primary.dark
            },
            '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
            },
        },
        fullWidthHover: {
            width: '100%',
            //text label
            '& .MuiFormLabel-root': {
                color: theme.palette.variants && theme.palette.variants.variant4
            },
            '& .MuiInput-underline': {
                '&::before': {
                    borderBottom: `1px solid ${theme.palette.variants && theme.palette.variants.variant2} !important`
                },
                '&:hover': {
                    borderBottom: `0px solid ${theme.palette.variants && theme.palette.variants.variant2}`
                },
            }

        },
        iconCheck: {
            color: '#23D9B7'
        },
        iconError: {
            color: '#FF2272'
        },
        iconDrawer: {
            color: '#fff',
            width: 15
        },
        iconEdit: {
            color: theme.palette.secondary.main,
            '&:hover': {
                cursor: 'pointer'
            }
        },
        inputIcon: {
            position: 'absolute',
            right: 0,
            bottom: 2
        },
        drawerIcon: {
            position: 'absolute',
            right: 0,
            top: 10
        }
    })
};

class TextInput extends ValidatorComponent {

    renderValidatorComponent() {
        const {
            classes,
            edit,
            value,
            handleEnableInput,
            disabled,
            errorMessages,
            validators,
            requiredError,
            validatorListener,
            isDrawer = false,
            config,
            ...rest
        } = this.props;
        const { isValid } = this.state;

        return (
            <div style={{ position: 'relative' }}>
                <TextField
                    {...rest}
                    value={value}
                    ref={(r) => { this.input = r; }}
                    error={!isValid ? true : false}
                    helperText={!isValid ? this.getErrorMessage() : false}
                    className={isDrawer ? classes.fullWidthDrawer : edit ? classes.fullWidthHover : classes.fullWidth}
                    InputProps={{
                        readOnly: edit || disabled ? true : false
                    }}
                    disabled={edit}
                />
                {
                    disabled ? (
                        null
                    ) : isValid && !edit ? (
                        <Box className={isDrawer ? classes.drawerIcon : classes.inputIcon}>
                            {isDrawer
                                ? <div style={{ backgroundColor: config.theme && config.theme.secondary.variant1, borderRadius: 50, width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Edit className={classes.iconDrawer} />
                                </div>
                                : <CheckCircleOutline className={classes.iconCheck} />
                            }
                        </Box>
                    ) : null
                }
                {
                    !isValid && isDrawer && (
                        <Box className={isDrawer ? classes.drawerIcon : classes.inputIcon}>
                            {isDrawer && <ErrorOutline className={classes.iconError} />}
                        </Box>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        config: state.userConfig.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
};

const ConnectedTextInput = connect(mapStateToProps, mapDispatchToProps)(TextInput);
export default withStyles(styles)(ConnectedTextInput);